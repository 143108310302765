<template>
  <div class="connection">
    <Navigation :routerValue="routerValue" />
    <div class="contactUs">
      <div class="topimg ">
        <img src="../assets/img/concact.png" alt="">
      </div>
    </div>
    <div class="form">
      <div class="title">
        <img src="../assets/img/u4315.png" alt="">
        <div>联系我们</div>
        <div>Contact Us</div>
      </div>
      <div class="form_box">
        <div class="form_left">
          <img src="../assets/img/zongbu.png" alt="">
          <div>地址：陕西省西安市未央区凤城十二路富尔顿大厦A座13层</div>
          <div>电话：400-186-1200</div>
          <div>邮箱：liangfang@163.com</div>
        </div>
        <div class="form_right">
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
            <el-form-item label="姓名" prop="name">
              <el-input v-model="ruleForm.name" placeholder="请输入姓名"></el-input>
            </el-form-item>
            <el-form-item label="电话" prop="phone" >
              <el-input v-model="ruleForm.phone"  placeholder="请输入电话"></el-input>
            </el-form-item>
            <el-form-item label="邮箱" prop="email">
              <el-input v-model="ruleForm.email"  placeholder="请输入邮箱"></el-input>
            </el-form-item>
            <el-form-item label="公司名称" prop="corporation">
              <el-input v-model="ruleForm.corporation"  placeholder="请输入公司名称"></el-input>
            </el-form-item>
            <el-form-item label="留言" prop="message">
              <el-input type="textarea"   :rows="11" v-model="ruleForm.message"  placeholder="请输入内容"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitForm('ruleForm')">提 交</el-button>
              <el-button @click="resetForm('ruleForm')">重 填</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <sidebarRight/>
    <bottom />
  </div>
</template>

<script>
import Navigation from '@/components/Navigation.vue'
import bottom from '@/components/bottom.vue'
import sidebarRight from '@/components/sidebarRight.vue'
export default {
  components: {
    Navigation,
    // eslint-disable-next-line vue/no-unused-components
    bottom,
    // eslint-disable-next-line vue/no-unused-components
    sidebarRight
  },
  data () {
    return {
      routerValue: 'contactUs',
      ruleForm: {
        name: undefined,
        phone: undefined,
        email: undefined,
        corporation: undefined,
        message: undefined
      },
      rules: {
        phone: [{ required: true, message: '请填写您的电话', trigger: 'blur' }]
      }
    }
  },
  methods: {
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert('submit!')
        }
      })
    },
    resetForm (formName) {
      this.$refs[formName].resetFields()
    }
  }
}
</script>

<style lang="scss" scoped>
.connection {
  width: 100%;
  height: 100%;
  background: #f5f5f5;

  .contactUs {
    width: 100%;
  }

  .form {
    width: 1500px;
    height: 650px;
    margin: 50px auto;

    .title {
      display: flex;
      align-items: center;

      :first-child {
        width: 40px;
        height: 40px;
      }

      :nth-child(2) {
        color: #333333;
        font-size: 25px;
        font-weight: 600;
        margin-left: 20px;
      }

      :last-child {
        margin-left: 20px;
        color: #13A3F0;
        font-size: 18px;
      }
    }

    .form_box {
      width: 1440px;
      margin: 25px 0 0 20px;
      height: 570px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      .form_left {
        background-color: #ffffff;
        border-radius: 10px;
        width: 45%;
        line-height: 50px;
        height: 100%;

        img {
          width: 645px;
          height: 350px;
          border-radius: 10px;
        }

        div {
          text-align: left;
          margin-left: 130px;
        }
      }
      .form_right{
        width: 45%;
        height: 100%;
      }
    }
  }
}</style>
